// Constants
import { ADDONS } from '@/constants'
// Components
import AdInformation from '@/components/elements/place/AdInformation'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Filters
import { formatDate } from '@/filters'
// Utils
import { get, isNil } from 'lodash'

// Date
const moment = require('moment')

export default {
  name: 'AdsQuestionnaire',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        advertisingEnabled: false,
        advertisingEnabledHistory: [],
        maximumCapacity: 0,
        typePlace: null,
        softDrinkBrands: [],
        beerBrands: [],
        coffeBrands: null,
        waterBrands: [],
        otherBrands: []
      },
      formFieldsValidations: {
        advertisingEnabled: {
          required: 'Campo obligatorio'
        },
        maximumCapacity: {
          required: 'Campo obligatorio',
          minValue: 'Debe ser un valor mayor o igual a 1'
        },
        typePlace: {
          required: 'Campo obligatorio'
        },
        softDrinkBrands: {
          required: 'Campo obligatorio'
        },
        beerBrands: {
          required: 'Campo obligatorio'
        },
        coffeBrands: {
          required: 'Campo obligatorio'
        },
        waterBrands: {
          required: 'Campo obligatorio'
        }
      },
      // Questions (answers)
      typePlaceAnswers: [
        {
          label: 'Bar',
          value: 'Bar'
        },
        {
          label: 'Cafetería',
          value: 'Cafetería'
        },
        {
          label: 'Grupo de restauración',
          value: 'Grupo de restauración'
        },
        {
          label: 'Hotel',
          value: 'Hotel'
        },
        {
          label: 'Local nocturno',
          value: 'Local nocturno'
        },
        {
          label: 'Restaurante',
          value: 'Restaurante'
        },
        {
          label: 'Otro',
          value: 'Otro'
        }
      ],
      softDrinkBrandsAnswers: [
        {
          label: '7 Up',
          value: '7 Up'
        },
        {
          label: 'Aquarius',
          value: 'Aquarius'
        },
        {
          label: 'Coca-Cola',
          value: 'Coca-Cola'
        },
        {
          label: 'Fanta',
          value: 'Fanta'
        },
        {
          label: 'Kas',
          value: 'Kas'
        },
        {
          label: 'La Casera',
          value: 'La Casera'
        },
        {
          label: 'Pepsi',
          value: 'Pepsi'
        },
        {
          label: 'Schweppes',
          value: 'Schweppes'
        },
        {
          label: 'Sprite',
          value: 'Sprite'
        },
        {
          label: 'Otro',
          value: 'Otro'
        },
        {
          label: 'Ninguno',
          value: 'Ninguno'
        }
      ],
      beerBrandsAnswers: [
        {
          label: 'Alhambra',
          value: 'Alhambra'
        },
        {
          label: 'Amstel',
          value: 'Amstel'
        },
        {
          label: 'Cruzcampo',
          value: 'Cruzcampo'
        },
        {
          label: 'Damm',
          value: 'Damm'
        },
        {
          label: 'Estrella Galicia',
          value: 'Estrella Galicia'
        },
        {
          label: 'Heineken',
          value: 'Heineken'
        },
        {
          label: 'Mahou',
          value: 'Mahou'
        },
        {
          label: 'San Miguel',
          value: 'San Miguel'
        },
        {
          label: 'Otro',
          value: 'Otro'
        },
        {
          label: 'Ninguno',
          value: 'Ninguno'
        }
      ],
      coffeBrandsAnswers: [
        {
          label: 'Baqué',
          value: 'Baqué'
        },
        {
          label: 'Bogani',
          value: 'Bogani'
        },
        {
          label: 'Bonka',
          value: 'Bonka'
        },
        {
          label: 'Brasilia',
          value: 'Brasilia'
        },
        {
          label: 'Cafenasa',
          value: 'Cafenasa'
        },
        {
          label: 'Cafés Aitona',
          value: 'Cafés Aitona'
        },
        {
          label: 'Cafés BOU',
          value: 'Cafés BOU'
        },
        {
          label: 'Cafés Granell',
          value: 'Cafés Granell'
        },
        {
          label: 'Cafés Hervás',
          value: 'Cafés Hervás'
        },
        {
          label: 'Cafés Templo',
          value: 'Cafés Templo'
        },
        {
          label: 'Cafés Toscaf',
          value: 'Cafés Toscaf'
        },
        {
          label: 'Cafés Unic',
          value: 'Cafés Unic'
        },
        {
          label: 'Catunambu',
          value: 'Catunambu'
        },
        {
          label: 'Illy',
          value: 'Illy'
        },
        {
          label: 'La Estrella',
          value: 'La Estrella'
        },
        {
          label: 'Lavazza',
          value: 'Lavazza'
        },
        {
          label: 'Saimaza',
          value: 'Saimaza'
        },
        {
          label: 'Santa Cristina',
          value: 'Santa Cristina'
        },
        {
          label: 'Segrafedo Zanetti',
          value: 'Segrafedo Zanetti'
        },
        {
          label: 'Otro',
          value: 'Otro'
        },
        {
          label: 'Ninguno',
          value: 'Ninguno'
        }
      ],
      waterBrandsAnswers: [
        {
          label: 'Aquabona',
          value: 'Aquabona'
        },
        {
          label: 'Bezolla',
          value: 'Bezolla'
        },
        {
          label: 'Cabreiroá',
          value: 'Cabreiroá'
        },
        {
          label: 'Fontbella',
          value: 'Fontbella'
        },
        {
          label: 'Lanjarón',
          value: 'Lanjarón'
        },
        {
          label: 'Nestlé Aquarel',
          value: 'Nestlé Aquarel'
        },
        {
          label: 'Solan de Cabras',
          value: 'Solan de Cabras'
        },
        {
          label: 'Veri',
          value: 'Veri'
        },
        {
          label: 'Viladrau',
          value: 'Viladrau'
        },
        {
          label: 'Otro',
          value: 'Otro'
        },
        {
          label: 'Ninguno',
          value: 'Ninguno'
        }
      ],
      otherBrandsAnswers: [
        {
          label: 'Don Siḿon',
          value: 'Don Simón'
        },
        {
          label: 'Lipton Ice Tea',
          value: 'Lipton Ice Tea'
        },
        {
          label: 'Minute Maid',
          value: 'Minute Maid'
        },
        {
          label: 'Monster',
          value: 'Monster'
        },
        {
          label: 'Nestea',
          value: 'Nestea'
        },
        {
          label: 'Red Bull',
          value: 'Red Bull'
        },
        {
          label: 'Otro',
          value: 'Otro'
        },
        {
          label: 'Ninguno',
          value: 'Ninguno'
        }
      ],
      // Others
      currencyData: [],
      languagesData: [],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * ¿Las opciones de publicidad están completadas (rellenadas)?
     *
     * @return {boolean}
     */
    advertisingOptionsIsCompleted() {
      const advertisingOptions = get(
        this.placeAddonsSetupByUser,
        `${ADDONS.place}.advertisingOptions`,
        {}
      )

      return (
        !isNil(advertisingOptions) &&
        typeof advertisingOptions === 'object' &&
        Object.keys(advertisingOptions).length > 0
      )
    },
    /**
     * Información acerca del estado de las ganancias por publicidad
     *
     * @return {string} - mensaje al cliente
     */
    infoAdsStatus() {
      // Estado de la activación
      const advertisingEnabled = get(this.placeConfig, 'advertisingEnabled', null)
      // Histórico de activaciones
      const advertisingEnabledHistory = get(this.placeConfig, 'advertisingEnabledHistory', null)
      // Última fecha de activación
      const advertisingEnabledDate = !isNil(advertisingEnabledHistory)
        ? advertisingEnabledHistory.filter((status) => status.enabled).pop()
        : null

      // No se muestra el mensaje
      if (isNil(advertisingEnabled) || !advertisingEnabled) {
        return false
      }

      // Mensaje por defecto, si está activado
      if (isNil(advertisingEnabledDate)) {
        return 'Las ganancias por publicidad están activadas'
      }

      // Ahora debemos mostrar si la publicidad ya está activa desde la fecha "N"
      // o será a partir de la fecha "N" cuando comience a estar activa
      const AdsStartDate = moment(advertisingEnabledDate.timestamp).add(1, 'M').startOf('month')

      return moment(AdsStartDate).isBefore(moment())
        ? `Las ganancias por publicidad comenzaron a contabilizar el día ${formatDate(
            AdsStartDate,
            'D MMMM YYYY'
          )}`
        : `Las ganancias por publicidad comenzarán a contabilizar a partir del día ${formatDate(
            AdsStartDate,
            'D MMMM YYYY'
          )}`
    },
    /**
     * Datos de configuración del establecimiento
     *
     * @return {object}
     */
    placeConfig() {
      return get(this.placeAddonsSetupByUser, ADDONS.place, {})
    }
  },
  watch: {
    advertisingOptionsIsCompleted: {
      handler(value) {
        if (!value) {
          // Mostramos información sobre la publicidad
          this.modifyAppDialog({
            title: 'Información',
            contentComponent: AdInformation,
            hideActionButtons: true,
            visible: true
          })
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Cuando cambia el valor de activación/desactivación
     * de publicidad
     *
     * @param {boolean} value - valor del campo
     */
    handleChangeAdvertisingEnabled(value) {
      const contentText = value
        ? '<p class="text-body-2 font-weight-bold">Activar ganancias por publicidad.</p><p>Debes tener en cuenta que <b>las ganancias se calculan por mes completo</b> y comenzando siempre en el día 1 de cada mes, es decir, aunque lo actives ahora mismo, será a partir del día 1 del mes siguiente cuando comencemos a calcular tus ganancias mes a mes y siempre y cuando no desactives esta opción hasta que finalice el mes en curso, en caso contrario, <b>perderás todo lo acumulado ese mismo mes</b>.</p><p class="text-body-2">Si eres un usuario de pago y activas la publicidad, hasta el día 1 del mes siguiente, no comenzará a aparecer en tu carta.</p>'
        : '<p class="text-body-2 font-weight-bold">Desactivar ganancias por publicidad.</p><p>Si así lo quieres, dejaremos de mostrar la publicidad en tu carta inmediatamente (si eres un usuario de pago) y <b>todos los beneficios que hayas podido obtener este mes se perderán</b>.</p>'

      this.modifyAppDialog({
        title: 'Información',
        contentText,
        optionsActionsButtons: {
          acceptButtonText: 'Entendido',
          acceptButtonType: 'button',
          cancelButtonHide: true
        },
        visible: true
      })
    },
    /**
     * Obtenemos o establecemos todos los datos
     * necesarios para vista en su carga inicial
     */
    getEveryNeededData() {
      // Set form fields
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      // Todas las respuestas del formulario
      const advertisingOptions = get(this.placeConfig, 'advertisingOptions', {})

      this.formFields = {
        advertisingEnabled: this.placeConfig.advertisingEnabled || false,
        advertisingEnabledHistory: this.placeConfig.advertisingEnabledHistory || [],
        maximumCapacity: advertisingOptions.maximumCapacity || 0,
        typePlace: advertisingOptions.typePlace || null,
        softDrinkBrands: advertisingOptions.softDrinkBrands || [],
        beerBrands: advertisingOptions.beerBrands || [],
        coffeBrands: advertisingOptions.coffeBrands || null,
        waterBrands: advertisingOptions.waterBrands || [],
        otherBrands: advertisingOptions.otherBrands || []
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      const { advertisingEnabled, advertisingEnabledHistory, ...otherAnswers } = this.formFields
      // Se ha modificado el estado de la publicidad (activa/desactiva)?
      const advertisingChanged = advertisingEnabled !== this.placeConfig.advertisingEnabled
      // Configuraciones a almacenar
      const addOns = [
        {
          id: ADDONS.place,
          configFields: {
            advertisingEnabled: advertisingEnabled,
            // Solo lo incluimos historial, si ha habido un cambio de estado
            ...(advertisingChanged
              ? {
                  advertisingEnabledHistory: [
                    ...advertisingEnabledHistory,
                    {
                      timestamp: new Date().getTime(),
                      enabled: advertisingEnabled
                    }
                  ]
                }
              : {}),
            advertisingOptions: { ...otherAnswers }
          }
        }
      ]

      // Actualizamos configuraciones (todas de una vez)
      await this.updateConfigs({
        id: this.placeData.id,
        addOns
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      advertisingEnabled: {
        required
      },
      advertisingEnabledHistory: {},
      maximumCapacity: {
        required,
        minValue: minValue(1)
      },
      typePlace: {
        required
      },
      softDrinkBrands: {
        required
      },
      beerBrands: {
        required
      },
      coffeBrands: {
        required
      },
      waterBrands: {
        required
      },
      otherBrands: {
        required
      }
    }
  }
}
