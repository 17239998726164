// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import AdsQuestionnaire from '../AdsQuestionnaire'
import AdsMovements from '../AdsMovements'

export default {
  name: 'adsByTabs',
  components: {
    VuetifyToolBar,
    VuetifyTabs
  },
  data() {
    return {
      items: [
        {
          label: 'Configuración',
          component: AdsQuestionnaire
        },
        {
          label: 'Movimientos',
          component: AdsMovements
        }
      ]
    }
  }
}
