// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'AdInformation',
  components: { FormButtons },
  mixins: [uiMixin],
  methods: {
    /**
     * Cuando pulsamos en el botón "aceptar"
     */
    handleAcceptButton() {
      // cerramos dialog
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Get path image
     *
     * @param {string} path - path to image ('public/img')
     * @param {Boolean} brand - is inside brand folder?
     * @return {string}
     */
    getImage(path, brand = false) {
      return getPathImage(path, brand)
    }
  }
}
